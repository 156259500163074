import { AlertStatuses } from '@customer-web-app/domains/alerts/models/alert';

export enum ActivityTypes {
  Comment = 'comment',
  StatusChange = 'status_change',
}

export enum ActivityEntityTypes {
  Incident = 'incident',
  Alert = 'alert',
}

export interface Activity {
  id: string;
  createdBy: string;
  createdByDisplayName: string;
  createdOn: Date | string;
  title: string;
  description: string;
  tenantID: string;
  entityID: string;
  entityType: ActivityEntityTypes;
  updatedOn: Date | string;
  updatedBy: string;
  stateChange?: {
    previousState: AlertStatuses;
    state: AlertStatuses;
  };
}
