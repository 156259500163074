import React from 'react';
import { ProgressBar, Text } from '@blastradius/ui';
import Skeleton from 'react-loading-skeleton';
import { Incident } from '@customer-web-app/domains/incidents/models/incident';
import classNames from 'classnames';

const IncidentProgressStep: React.FC<{
  label: string;
  totalSteps: number;
  currentStep: number;
  loading: boolean;
  manySteps: boolean;
  incident: Incident;
  showCounters?: boolean;
}> = ({
  label,
  totalSteps,
  currentStep,
  loading,
  manySteps,
  incident,
  showCounters = true,
}) => {
  let progress;
  const hasZeroSteps = currentStep === 0 && totalSteps === 0;
  const isIncomplete =
    totalSteps === null ? true : currentStep < totalSteps && totalSteps > 0;
  const incidentInvestigationError = incident?.investigationError?.code;
  const incidentNotEnoughData =
    incidentInvestigationError && label === 'Containment';

  if (totalSteps === null) {
    progress = 0;
  } else if (manySteps) {
    progress =
      currentStep === 0 && totalSteps === 0 ? 1 : currentStep / totalSteps;
  } else {
    progress = isIncomplete ? 0 : 1;
  }

  function getProgressBarColor() {
    if (incidentNotEnoughData) {
      return 'gray';
    }

    if (hasZeroSteps) {
      return 'green';
    }

    return isIncomplete ? 'red' : 'green';
  }

  return (
    <div className="flex flex-col z-10">
      {!loading ? (
        <ProgressBar
          progress={hasZeroSteps || incidentInvestigationError ? 1 : progress}
          color={getProgressBarColor()}
          className="mb-2"
          aria-label={`A ${getProgressBarColor()} progress bar`}
          data-testid={`${label}-progress-bar`}
        />
      ) : (
        <Skeleton height={2} className="mb-1" />
      )}

      {!loading ? (
        <Text
          type="label"
          color="text-gray-500"
          as="div"
          data-testid={`${label}-progress-summary`}
        >
          {label}
          {showCounters &&
            (isIncomplete || hasZeroSteps) &&
            !incidentNotEnoughData &&
            totalSteps !== null && (
              <>
                {' '}
                <Text
                  type="body"
                  size="small"
                  aria-label={`Step ${currentStep} of ${totalSteps}`}
                >
                  {currentStep}/{totalSteps}
                </Text>
              </>
            )}
        </Text>
      ) : (
        <Skeleton width={64} height={12} />
      )}
    </div>
  );
};

type Props = {
  incident: Incident;
  loading?: boolean;
};

const IncidentProgressStepBar: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ incident, loading, className, ...props }) => (
  <div className={classNames('grid grid-cols-4 gap-2', className)} {...props}>
    <IncidentProgressStep
      label="Triage"
      currentStep={1}
      totalSteps={1}
      loading={loading}
      manySteps={false}
      incident={incident}
    />
    <IncidentProgressStep
      label="Investigation"
      currentStep={
        incident?.stepsCount?.investigation?.inprogress > 0
          ? incident?.stepsCount?.investigation?.total / 2
          : incident?.stepsCount?.investigation?.done ===
              incident?.stepsCount?.investigation?.total
            ? incident?.stepsCount?.investigation?.done
            : 0
      }
      totalSteps={incident?.stepsCount?.investigation?.total}
      loading={loading}
      manySteps
      showCounters={false}
      incident={incident}
    />
    <IncidentProgressStep
      label="Containment"
      currentStep={incident?.stepsCount?.containment?.done}
      totalSteps={incident?.stepsCount?.containment?.total}
      loading={loading}
      manySteps={true}
      incident={incident}
    />
    <IncidentProgressStep
      label="Remediation"
      currentStep={incident?.stepsCount?.remediation?.done}
      totalSteps={incident?.stepsCount?.remediation?.total}
      loading={loading}
      manySteps={true}
      incident={incident}
    />
  </div>
);

export default IncidentProgressStepBar;
