import useToast from '@blastradius/ui/hooks/use-toast';
import api from '@customer-web-app/domains/shared/api';
import swrOptimisticUpdate from '@customer-web-app/domains/shared/services/swr-optimistic-update';
import React from 'react';
import { useSWRConfig } from 'swr';
import {
  Activity,
  ActivityEntityTypes,
  ActivityTypes,
} from '@customer-web-app/domains/activities/models/activity';
import { HttpStatusCode } from '@customer-web-app/domains/shared/api/http-status-code';
import { AlertStatuses } from '@customer-web-app/domains/alerts/models/alert';

const commentsErrorMessages: {
  [key in HttpStatusCode]?: string;
} = {
  [HttpStatusCode.ContentTooLarge]:
    'Message too large. Please reduce and try again.',
};

type CreateActivityCommentParams = Partial<Activity> & {
  entity: ActivityEntityTypes;
  entityID: string;
  activityType: ActivityTypes;
  title?: string;
  description: string;
  stateChange?: {
    previousState: AlertStatuses;
    state: AlertStatuses;
  };
  withNotification?: boolean;
};

type CreateBulkActivityCommentParams = Partial<Activity> & {
  entity: ActivityEntityTypes;
  entityIDs: string[];
  activityType: ActivityTypes;
  title?: string;
  description?: string;
  withNotification?: boolean;
};

function useActivity() {
  const [isCommentCreating, setIsCommentCreating] = React.useState(false);
  const [isCommentUpdating, setIsCommentUpdating] =
    React.useState<string>(null);
  const { notification } = useToast();
  const { mutate } = useSWRConfig();

  async function createActivityComment({
    entityID,
    entity,
    activityType,
    title,
    description,
    createdBy,
    stateChange,
    withNotification = true,
  }: CreateActivityCommentParams) {
    setIsCommentCreating(true);

    try {
      const commentDate = new Date().toISOString();

      const { data: id } = await api.post('/activities/insert', {
        activityType,
        title,
        entity,
        entityID,
        ...(description ? { description } : {}),
        ...(stateChange?.previousState ? { stateChange } : {}),
      });

      swrOptimisticUpdate<{ items: Partial<Activity>[] }>(
        `/activities/list/${entity}/${entityID}/${activityType}`,
        (cache) => {
          const comments = cache?.items || [];

          const newComment = {
            title,
            description,
            createdOn: commentDate,
            createdBy,
            id,
            stateChange,
          };

          return {
            items: [newComment, ...comments],
          };
        },
        {
          mutate,
        },
      );

      if (withNotification) {
        notification({
          title: 'Comment Created',
          message: `The incident comment has been created`,
          icon: 'message-ballon',
          iconContained: true,
          position: 'bottom-left',
          options: [{ type: 'dismiss' }],
        });
      }

      return id;
    } catch (e) {
      if (withNotification) {
        notification({
          title: 'Comment Error',
          message:
            commentsErrorMessages?.[e?.status] ||
            `The incident comment cannot be created. Please try again.`,
          icon: 'message-ballon',
          iconContained: true,
          position: 'bottom-left',
          options: [{ type: 'dismiss' }],
        });
      }

      throw e;
    } finally {
      setIsCommentCreating(false);
    }
  }

  async function createBulkActivityComment({
    entityIDs,
    entity,
    activityType,
    title,
    description,
    createdBy,
    withNotification,
  }: CreateBulkActivityCommentParams) {
    setIsCommentCreating(true);

    try {
      const commentDate = new Date().toISOString();

      const { data: id } = await api.post('/activities/insert/bulk', {
        activityType,
        entity,
        entityIDs,
        title,
        description,
      });

      entityIDs.forEach((entityID) => {
        swrOptimisticUpdate<{ items: Partial<Activity>[] }>(
          `/activities/list/${entity}/${entityID}/${activityType}`,
          (cache) => {
            const comments = cache?.items || [];

            const newComment = {
              title,
              description,
              createdOn: commentDate,
              createdBy,
              id,
            };

            return {
              items: [newComment, ...comments],
            };
          },
          {
            mutate,
          },
        );
      });

      if (withNotification) {
        notification({
          title: 'Comments Created',
          message: `The incidents comments has been created`,
          icon: 'message-ballon',
          iconContained: true,
          position: 'bottom-left',
          options: [{ type: 'dismiss' }],
        });
      }

      return id;
    } catch (e) {
      if (withNotification) {
        notification({
          title: 'Comments Error',
          message:
            commentsErrorMessages?.[e?.status] ||
            `The incidents comments cannot be created. Please try again.`,
          icon: 'message-ballon',
          iconContained: true,
          position: 'bottom-left',
          options: [{ type: 'dismiss' }],
        });
      }
      throw e;
    } finally {
      setIsCommentCreating(false);
    }
  }

  async function updateActivityComment({
    id,
    entity,
    entityID,
    activityType,
    title,
    description,
  }: Partial<Activity> & {
    entity: ActivityEntityTypes;
    entityID: string;
    activityType: ActivityTypes;
  }) {
    setIsCommentUpdating(id);

    try {
      const updatedCommentDate = new Date().toISOString();

      const { data } = await api.patch(`/activities/update/${id}`, {
        title,
        description,
      });

      notification({
        title:
          entity === ActivityEntityTypes.Incident
            ? 'Comment Updated'
            : `Justification Updated`,
        message:
          entity === ActivityEntityTypes.Incident
            ? `The incident comment has been updated`
            : `The triage justification has been updated`,
        icon: 'message-ballon',
        iconContained: true,
        position: 'bottom-left',
        options: [{ type: 'dismiss' }],
      });

      swrOptimisticUpdate<{ items: Partial<Activity>[] }>(
        `/activities/list/${entity}/${entityID}/${activityType}`,
        (cache) => {
          const cachedItems = cache?.items || [];

          return {
            items: cachedItems.map((comment) =>
              comment?.id === id
                ? {
                    ...comment,
                    title,
                    description,
                    updatedOn: updatedCommentDate,
                  }
                : comment,
            ),
          };
        },
        {
          mutate,
        },
      );

      return data;
    } catch (e) {
      notification({
        title: 'Comment Error',
        message:
          commentsErrorMessages?.[e?.status] ||
          `The incident comment cannot be updated. Please try again.`,
        icon: 'message-ballon',
        iconContained: true,
        position: 'bottom-left',
        options: [{ type: 'dismiss' }],
      });
      throw e;
    } finally {
      setIsCommentUpdating(null);
    }
  }

  async function deleteActivityComment({
    id,
    entity,
    entityID,
    activityType,
  }: {
    id: string;
    entity: ActivityEntityTypes;
    entityID: string;
    activityType: ActivityTypes;
  }) {
    setIsCommentUpdating(id);

    try {
      swrOptimisticUpdate<{ items: Partial<Activity>[] }>(
        `/activities/list/${entity}/${entityID}/${activityType}`,
        (cache) => {
          const cachedItems = cache?.items || [];

          return {
            items: cachedItems.filter((comment) => comment?.id !== id),
          };
        },
        {
          mutate,
        },
      );

      const { data } = await api.delete(`/activities/delete/${id}`);

      notification({
        title: 'Comment Deleted',
        message: `The incident comment has been deleted`,
        icon: 'message-ballon',
        iconContained: true,
        position: 'bottom-left',
        options: [{ type: 'dismiss' }],
      });

      return data;
    } catch (e) {
      notification({
        title: 'Comment Error',
        message: `The incident comment cannot be deleted. Please try again.`,
        icon: 'message-ballon',
        iconContained: true,
        position: 'bottom-left',
        options: [{ type: 'dismiss' }],
      });
      throw e;
    } finally {
      setIsCommentUpdating(null);
    }
  }

  return {
    createActivityComment,
    createBulkActivityComment,
    updateActivityComment,
    deleteActivityComment,
    isCommentCreating,
    isCommentUpdating,
  };
}

export default useActivity;
