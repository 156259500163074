export enum AlertReportTypes {
  FalsificationReport = 'falsification-report',
}

export enum AlertReportObservableTypes {
  File = 'file',
  Link = 'link',
  SenderEmail = 'sender-email',
  ReplyToEmail = 'reply-to-email',
  IP = 'ip',
}

export enum AlertReportIPObservableTypes {
  SourceIP = 'source',
  DestinationIP = 'destination',
}

export interface AlertReport {
  id: string;
  reportID: string;
  displayName: string;
  observableType: AlertReportObservableTypes;
  createdOn: Date;
  tenantID: string;
  reportType: AlertReportTypes;
  createdBy: string;
  email?: string;
  link?: { url: string };
  file?: {
    file: { filename: string };
    hash: {
      md5: string;
      sha1: string;
      sha256: string;
    };
    size: {
      bytes: number;
    };
    type?: {
      name: string;
      category: string;
      type: string;
    };
  };
  ip?: {
    type: AlertReportIPObservableTypes;
    ip: string;
  };
  duplicated?: boolean;
}
